<template>
  <div>
    <div class="page-wrapper-table booking-table">
      <!-- region formulaire de recherche des réservations -->
      <div class="page-wrapper-table-header">
        <h2>{{ $t("COMMON.RESERVATION_ON_CLICK") }}</h2>
        <div class="booking-search-form">
          <base-input class="search-input">
            <el-input
              placeholder="Rechercher nom emplacement"
              v-model="searchCriteria.search"
              clearable
            ></el-input>
          </base-input>

          <base-input class="search-dates">
            <el-date-picker
              v-model="searchCriteria.dateRange"
              type="daterange"
              start-placeholder="Date de début"
              end-placeholder="Date de fin"
              format="dd MMM yyyy"
              value-format="yyyy-MM-dd"
              :picker-options="{ disabledDate: isDisabledDate }"
            >
            </el-date-picker>
          </base-input>

          <el-popover
            placement="bottom"
            trigger="click"
            v-model="capacityFilterPopOverDisplayed"
            class="search-number"
            visible-arrow="false"
          >
            <base-input
              class="number-selector"
              :label="$t('BOOKINGS.ADULTS_CAPACITY')"
              :placeholder="$t('BOOKINGS.ADULTS_CAPACITY')"
            >
              <el-input-number
                v-model="searchCriteria.adults_capacity"
                :min="2"
                :max="9"
              />
            </base-input>
            <base-input
              class="number-selector"
              :label="$t('BOOKINGS.CHILDREN_CAPACITY')"
              :placeholder="$t('BOOKINGS.CHILDREN_CAPACITY')"
            >
              <el-input-number
                v-model="searchCriteria.children_capacity"
                :min="0"
                :max="9"
              />
            </base-input>
            <base-input
              class="number-selector"
              :label="$t('BOOKINGS.PETS_CAPACITY')"
              :placeholder="$t('BOOKINGS.PETS_CAPACITY')"
            >
              <el-input-number
                v-model="searchCriteria.pets_capacity"
                :min="0"
                :max="10"
              />
            </base-input>
            <el-button
              @click="
                () => {
                  getListDebounced();
                  capacityFilterPopOverDisplayed =
                    !capacityFilterPopOverDisplayed;
                }
              "
              size="sm"
            >
              {{ $t("COMMON.APPLY") }}
            </el-button>
            <el-button slot="reference">
              {{ $t("BOOKINGS.CAPACITY") }} : {{ totalBookingCapacity ?? 0 }}
              <i class="el-icon-arrow-down"></i>
            </el-button>
          </el-popover>

          <div class="submit">
            <el-button @click="getListDebounced" type="primary" size="large">
              <span class="icon">
                <i class="far fa-search"></i>
              </span>
              <span class="text">
                {{ $t("COMMON.SEARCH") }}
              </span>
            </el-button>
          </div>
        </div>
      </div>
      <!-- endregion -->

      <div
        :class="
          'page-wrapper-table-body ' +
          (filterReduced ? 'page-wrapper-table-body--folded' : '')
        "
      >
        <!-- region filtres de recherche latérale -->
        <div class="page-wrapper-table-body-left">
          <div class="filter">
            <div class="filter-fold">
              <button
                v-if="!filterReduced"
                @click="() => (filterReduced = true)"
              >
                <img src="/img/kw-double-arrow-left.svg" alt="icon" />
                <span>{{ $t("COMMON.REDUCE") }}</span>
              </button>
              <button
                v-if="filterReduced"
                @click="() => (filterReduced = false)"
              >
                <img src="/img/kw-double-arrow-right.svg" alt="icon" />
              </button>
            </div>
            <div class="filter-inner">
              <div class="filter-inner-title">
                <h2>
                  <span>{{ $t("COMMON.FILTERS") }}</span>
                </h2>
                <el-button
                  type="text"
                  icon="far fa-redo-alt"
                  @click="resetFilters"
                  >{{ $t("COMMON.RESET_FILTERS") }}
                </el-button>
              </div>
              <div class="filter-inner-item">
                <h4>{{ $t("BOOKINGS.AVAILABILITY") }}</h4>
                <el-radio-group v-model="filters.availability">
                  <ul>
                    <li>
                      <el-radio :label="null">{{ $t("COMMON.ALL") }}</el-radio>
                    </li>
                    <li>
                      <el-radio :label="SPOT_STATUS_AVAILABLE">
                        {{ $t("BOOKINGS.AVAILABLE") }}
                      </el-radio>
                    </li>
                    <li>
                      <el-radio :label="SPOT_STATUS_PARTIALY_AVAILABLE">
                        {{ $t("BOOKINGS.PARTIALLY_AVAILABLE") }}
                      </el-radio>
                    </li>
                  </ul>
                </el-radio-group>
              </div>
              <!-- <div class="filter-inner-item">
                <h4>{{ $t("COMMON.PRICE") }}</h4>
                <base-input>
                  <div class="slider-range">
                    <span>{{ filters.priceRange[0] }} CAD</span>
                    <span>{{ filters.priceRange[1] }} CAD</span>
                  </div>
                  <el-slider
                    v-model="filters.priceRange"
                    range
                    :show-tooltip="false"
                    :max="5000"
                    :step="50"
                  />
                </base-input>
              </div> -->
              <div class="filter-inner-item">
                <h4>{{ $t("BOOKINGS.FILTER_BY_SPOT_LENGTH") }}</h4>
                <base-input>
                  <div class="slider-range">
                    <span>
                      {{ filters.equipmentLengthRange[0] }}
                      {{ $t("COMMON.UNIT_FEET") }}
                    </span>
                    <span>
                      {{ filters.equipmentLengthRange[1] }}
                      {{ $t("COMMON.UNIT_FEET") }}
                    </span>
                  </div>
                  <el-slider
                    v-model="filters.equipmentLengthRange"
                    range
                    :show-tooltip="false"
                    :step="10"
                    :max="100"
                  />
                </base-input>
              </div>
              <div class="filter-inner-item">
                <h4>{{ $t("BOOKINGS.FILTER_BY_SPOT_CATEGORY") }}</h4>
                <el-checkbox
                  :value="filters.spotCategories.length === 0"
                  @change="() => (filters.spotCategories = [])"
                >
                  {{ $t("COMMON.ALL") }}
                </el-checkbox>
                <el-checkbox-group v-model="filters.spotCategories">
                  <ul>
                    <li v-for="cat in spotCategories" :key="cat.id">
                      <el-checkbox :label="cat.id">{{ cat.name }}</el-checkbox>
                    </li>
                  </ul>
                </el-checkbox-group>
              </div>
              <!-- region Filter by spot types -->
              <div class="filter-inner-item">
                <el-collapse :value="0" accordion>
                  <el-collapse-item>
                    <template slot="title">
                      <span class="h4-for-el-collapse-item">
                        {{ $t("BOOKINGS.FILTER_BY_SPOT_TYPE") }}
                      </span>
                    </template>
                    <!-- Select All -->
                    <el-checkbox
                      :value="filters.spotTypes.length === 0"
                      @change="() => (filters.spotTypes = [])"
                    >
                      {{ $t("COMMON.ALL") }}
                    </el-checkbox>
                    <!-- Individual Items -->
                    <el-checkbox-group v-model="filters.spotTypes">
                      <ul>
                        <li v-for="type in spotTypes" :key="type.id">
                          <el-checkbox :label="type.id">
                            {{ type.name }}
                          </el-checkbox>
                        </li>
                      </ul>
                    </el-checkbox-group>
                  </el-collapse-item>
                </el-collapse>
              </div>
              <!-- endregion -->
              <div class="filter-inner-item">
                <h4>{{ $t("BOOKINGS.FILTER_BY_EQUIPMENT") }}</h4>
                <el-collapse :value="0">
                  <el-collapse-item
                    v-for="cat in equipmentCategories"
                    :key="cat.id"
                    :title="cat.name"
                    :name="cat.id"
                  >
                    <el-checkbox
                      :value="allSelected(cat.equipments)"
                      @change="
                        (selected) => {
                          selected
                            ? selectAll(cat.equipments)
                            : unSelectAll(cat.equipments);
                        }
                      "
                    >
                      {{ $t("COMMON.ALL") }}
                    </el-checkbox>
                    <el-checkbox-group v-model="filters.equipments">
                      <ul>
                        <li v-for="eq in cat.equipments" :key="eq.id">
                          <el-checkbox :label="eq.id">
                            {{ eq.name }}
                          </el-checkbox>
                        </li>
                      </ul>
                    </el-checkbox-group>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>
        </div>
        <!-- endregion -->
        <div class="page-wrapper-table-body-right">
          <div class="bookings-list">
            <div class="bookings-list-header">
              <div class="bookings-list-header-left">
                <h3 v-if="searchCriteria.dateRange.length > 0">
                  {{ $t("BOOKINGS.SPOTS_LIST_FOUND", { total: total }) }}
                  <i class="far fa-spinner fa-spin" v-show="loading"></i>
                </h3>
                <h3 v-if="searchCriteria.dateRange.length === 0">
                  {{ $t("BOOKINGS.SELECT_DATE_RANGE", { total: total }) }}
                  <i class="far fa-spinner fa-spin" v-show="loading"></i>
                </h3>
              </div>
              <div class="bookings-list-header-right">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  :placeholder="$t('COMMON.PER_PAGE')"
                  v-if="activeTab === LIST_TAB"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <div class="chips">
                  <button
                    class="btn"
                    :class="{ 'bg-light': activeTab == LIST_TAB }"
                    @click="activeTab = LIST_TAB"
                  >
                    <i class="far fa-list"></i>
                    <span>{{ $t("COMMON.LIST") }}</span>
                  </button>
                  <button
                    class="btn"
                    :class="{ 'bg-light': activeTab == MAP_TAB }"
                    @click="activeTab = MAP_TAB"
                  >
                    <img src="/img/kw-map.svg" alt="icon" />
                    <span>{{ $t("COMMON.MAP") }}</span>
                  </button>
                  <button
                    class="btn"
                    :class="{ 'bg-light': activeTab == CALENDAR_TAB }"
                    @click="activeTab = CALENDAR_TAB"
                  >
                    <img src="/img/kw-calendar-2.svg" alt="icon" />
                    <span>{{ $t("COMMON.CALENDAR") }}</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="bookings-list-content">
              <div
                class="booking-list-content-wrapper"
                v-if="activeTab === LIST_TAB"
              >
                <div
                  class="item"
                  v-for="spot in spots"
                  :key="spot.id"
                  @click="newBookingFromSpot(spot, searchCriteria)"
                >
                  <booking-spot-list-item :spot="spot" />
                </div>
              </div>
              <div class="calendar-wrapper" v-if="activeTab === CALENDAR_TAB">
                <div class="calendar-wrapper-refresh">
                  <button class="btn">
                    <img src="/img/kw-refresh.svg" alt="icon" />
                    <span> {{ $t("COMMON.REFRESH") }} </span>
                  </button>
                </div>
                <div class="calendar-wrapper-filter">
                  <base-input
                    type="search"
                    class="search"
                    :placeholder="$t('COMMON.SEARCH')"
                    v-model="calendarFilter.search"
                    prepend-icon="far fa-search"
                    clearable
                  />
                  <el-select
                    v-model="calendarFilter.date"
                    class=""
                    :placeholder="$t('COMMON.DATE')"
                  >
                    <el-option :label="$t('COMMON.TODAY')" value="today">
                    </el-option>
                  </el-select>
                  <spot-type-selector
                    :spotType="calendarFilter.spotType"
                    :filterable="true"
                    @spotTypeChanged="
                      (spotType) => (calendarFilter.spotType = spotType)
                    "
                  />
                  <el-select
                    v-model="calendarFilter.status"
                    class=""
                    :placeholder="$t('COMMON.STATUS')"
                  >
                    <el-option :label="$t('COMMON.STATUS')" :value="null" />
                    <el-option
                      :label="$t(`SPOTS.STATUS_${status}`)"
                      :value="status"
                      v-for="status in spotStatusOptions"
                      :key="status"
                    />
                  </el-select>
                  <el-button
                    class="reset"
                    type="text"
                    icon="far fa-redo-alt"
                    @click="resetCalendarFilters"
                  >
                    <span>{{ $t("COMMON.RESET_FILTERS") }}</span>
                  </el-button>
                </div>
                <calendar-component
                  :spots="spots"
                  @onCleanSpot="cleanSpot"
                  @onAddBooking="onAddBooking"
                  @onViewBooking="onViewBooking"
                  @onOutOfService="onOutOfService"
                  @onCheckInBooking="checkInBooking"
                  @onCheckOutBooking="checkOutBooking"
                  @onBackIntoService="putBackBackIntoService"
                />
              </div>

              <div class="map-wrapper" v-if="activeTab === MAP_TAB">
                <spots-map-component
                  :spots="spots"
                  :searchCriteria="searchCriteria"
                  @onAddBooking="onAddBooking"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- endregion -->
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
    <out-of-service-confirmation-modal
      :open.sync="openOutOfConfirmation"
      :onConfirm="putSpotOutOfService"
    />
  </div>
</template>
<script>
import _, { cloneDeep } from "lodash";
import fr from "element-ui/lib/locale/lang/fr";
import locale from "element-ui/lib/locale";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Input,
  InputNumber,
  Option,
  Radio,
  RadioGroup,
  Row,
  Select,
  Slider,
  Table,
  TableColumn,
  Tooltip,
  Collapse,
  CollapseItem,
  Switch,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import BookingSpotListItem from "./SpotListItem.vue";
import {
  SPOT_STATUS_AVAILABLE,
  SPOT_STATUS_PARTIALY_AVAILABLE,
  spotStatusOptions,
} from "@/constants/common";
import CalendarComponent from "@/components/CalendarComponent.vue";
import SpotTypeSelector from "@/components/SpotTypeSelector.vue";
import OutOfServiceConfirmationModal from "@/components/OutOfServiceConfirmationModal.vue";
import defaultBooking from "../defaultBooking";
import { BOOKING_STATUS_OUT_OF_SERVICE } from "@/constants/common";
import swal from "sweetalert2";
import moment from "moment";
import SpotsMapComponent from "@/components/SpotsMapComponent.vue";

locale.use(fr);
const MAX_PRICE = 5000;
const MAX_EQUIPMENT_LENGTH = 100;

export default {
  name: "booking-spot-list-table",

  components: {
    BookingSpotListItem,
    BasePagination,
    CalendarComponent,
    SpotTypeSelector,
    OutOfServiceConfirmationModal,
    SpotsMapComponent,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Slider.name]: Slider,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Input.name]: Input,
    [InputNumber.name]: InputNumber,
    [Row.name]: Row,
    [Col.name]: Col,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Switch.name]: Switch,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    tab: {
      type: String,
      default: null,
    },
  },

  data() {
    this.fetchEquipmentCategories();

    const LIST_TAB = "list";
    const MAP_TAB = "map";
    const CALENDAR_TAB = "calendar";

    const activeTab = this.tab ?? LIST_TAB;

    return {
      query: null,
      selectedRows: [],
      sort: "code",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      spots: [],
      loading: false,
      filterReduced: false,
      filters: {
        availability: null,
        spotCategories: [],
        spotTypes: [],
        equipments: [],
        equipmentCategories: [],
        priceRange: [0, MAX_PRICE],
        equipmentLengthRange: [0, MAX_EQUIPMENT_LENGTH],
      },
      searchCriteria: {
        search: null,
        dateRange: [],
        adults_capacity: null,
        children_capacity: null,
        pets_capacity: null,
      },
      spotCategories: [],
      spotTypes: [],
      equipments: [],
      equipmentCategories: [],
      capacityFilterPopOverDisplayed: false,
      selectedOrganization: null,
      selectedLocations: null,
      SPOT_STATUS_AVAILABLE,
      SPOT_STATUS_PARTIALY_AVAILABLE,
      LIST_TAB,
      MAP_TAB,
      CALENDAR_TAB,
      activeTab,
      calendarFilter: {
        date: "today",
        status: null,
        search: "",
        spotType: null,
      },
      spotStatusOptions,
      openOutOfConfirmation: false,
      outOfServiceBooking: null,
    };
  },

  created() {
    this.fetchSpotCategories();
    this.fetchSpotTypes();
    this.fetchEquipments();
  },
  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    totalBookingCapacity() {
      return (
        this.searchCriteria.adults_capacity +
        this.searchCriteria.children_capacity
      );
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    activeTab: {
      handler: "getList",
      immediate: false,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedSpotTypes: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedSpotCategories: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedEquipments: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
    filters: {
      handler: "getListDebounced",
      immediate: true,
      deep: true,
    },
    searchCriteria: {
      handler: "getListDebounced",
      immediate: true,
      deep: true,
    },
    calendarFilter: {
      handler: "getListDebounced",
      immediate: true,
      deep: true,
    },
    activeTab(value) {
      this.$emit("update:tab", value);
    },
    tab(value) {
      if (value != this.activeTab) {
        this.activeTab = value;
      }
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 500),

    async getList() {
      if (this.searchCriteria.dateRange.length === 0) {
        return false;
      }

      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {},
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include:
            "organization,category,spotType,other_equipments,bookings,bookings.customer",
        };

        if (this.activeTab == this.CALENDAR_TAB) {
          if (this.calendarFilter.search) {
            params.filter.search = this.calendarFilter.search;
          }

          if (this.calendarFilter.spotType) {
            params.filter.in_types = [this.calendarFilter.spotType];
          }

          if (this.calendarFilter.status) {
            params.filter.status = this.calendarFilter.status;
          }
        } else {
          if (this.searchCriteria.search) {
            params.filter.search = this.searchCriteria.search;
          }

          params.filter.availability_date_range =
            this.searchCriteria.dateRange.map((date) =>
              moment(date).toISOString()
            );

          if (this.searchCriteria.adults_capacity) {
            params.filter.adults_capacity = this.searchCriteria.adults_capacity;
          }
        }

        if (this.searchCriteria.children_capacity) {
          params.filter.children_capacity =
            this.searchCriteria.children_capacity;
        }

        if (this.searchCriteria.pets_capacity) {
          params.filter.pets_capacity = this.searchCriteria.pets_capacity;
        }

        if (this.filters.spotTypes && this.filters.spotTypes.length > 0) {
          params.filter.in_types = this.filters.spotTypes;
        }

        if (this.filters.availability) {
          params.filter.status = this.filters.availability;
        }

        if (this.filters.priceRange) {
          if (
            this.filters.priceRange[0] !== 0 ||
            this.filters.priceRange[1] !== MAX_PRICE
          ) {
            params.filter.price_range = this.filters.priceRange;
          }
        }

        if (this.filters.spotCategories) {
          params.filter.in_categories = this.filters.spotCategories;
        }

        if (this.filters.equipmentLengthRange) {
          if (
            this.filters.equipmentLengthRange[0] !== 0 ||
            this.filters.equipmentLengthRange[1] !== MAX_EQUIPMENT_LENGTH
          ) {
            params.filter.equipment_length_range =
              this.filters.equipmentLengthRange;
          }
        }

        if (this.filters.equipments) {
          params.filter.has_equipments = this.filters.equipments;
        }

        const organisationFilter =
          this.filterOrganization ?? this.selectedOrganization;

        if (organisationFilter) {
          params = {
            ...params,
            filter: { ...params.filter, organization: organisationFilter },
          };
        }

        await this.$store.dispatch("spots/list", params);
        this.spots = this.$store.getters["spots/list"];
        this.total = this.$store.getters["spots/listTotal"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async fetchSpotCategories() {
      let params = { filter: { show_as_filter: 1 } };

      if (this.spot?.organization?.id) {
        params = {
          ...params,
          filter: { ...params.filter, organization: this.spot.organization.id },
        };
      }

      try {
        await this.$store.dispatch("spotCategories/list", params);

        this.spotCategories = this.$store.getters["spotCategories/list"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async fetchSpotTypes() {
      let params = { filter: { show_as_filter: 1 } };

      if (this.spot?.organization?.id) {
        params = {
          ...params,
          filter: { ...params.filter, organization: this.spot.organization.id },
        };
      }

      try {
        await this.$store.dispatch("spotTypes/list", params);

        this.spotTypes = this.$store.getters["spotTypes/list"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async fetchEquipments() {
      let params = { filter: { show_as_filter: 1 } };

      if (this.spot?.organization?.id) {
        params = {
          ...params,
          filter: { ...params.filter, organization: this.spot.organization.id },
        };
      }

      try {
        await this.$store.dispatch("equipments/list", params);

        this.equipments = this.$store.getters["equipments/list"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async fetchEquipmentCategories() {
      try {
        this.processing = true;
        let params = {
          filter: { show_as_filter: 1 },
          page: {
            number: 1,
            size: 999,
          },
          include: "equipments",
        };

        if (this.spotType?.organization?.id) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.spotType?.organization?.id,
            },
          };
        }
        await this.$store.dispatch("equipmentCategories/list", params);
        this.equipmentCategories =
          this.$store.getters["equipmentCategories/list"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    unSelect(equipment) {
      this.filters.equipments = this.filters.equipments.filter(
        (id) => id !== equipment.id
      );
    },
    select(equipment) {
      if (!this.filters.equipments.includes(equipment.id)) {
        this.filters.equipments.push(equipment.id);
      }
    },
    unSelectAll(equipmentList) {
      equipmentList.forEach((eq) => this.unSelect(eq));
    },
    selectAll(equipmentListIds) {
      equipmentListIds.forEach((id) => this.select(id));
    },
    allSelected(equipmentList) {
      const unselected = equipmentList.filter(
        (equipment) => !this.filters.equipments.includes(equipment.id)
      );

      return unselected.length === 0;
    },

    newBookingFromSpot(spot, searchCriteria) {
      if (searchCriteria) {
        this.$emit(
          "onCreateBooking",
          spot.id,
          searchCriteria.dateRange[0],
          searchCriteria.dateRange[1],
          false,
          searchCriteria
        );
      } else {
        this.$emit("onCreateBooking", spot.id, null, null, false);
      }
    },

    resetFilters() {
      this.filters = {
        availability: null,
        spotCategories: [],
        spotTypes: [],
        equipments: [],
        equipmentCategories: [],
        priceRange: [0, MAX_PRICE],
        equipmentLengthRange: [0, MAX_EQUIPMENT_LENGTH],
      };
    },
    resetCalendarFilters() {
      this.calendarFilter = {
        date: "today",
        status: null,
        search: "",
        spotType: null,
      };
    },

    onViewBooking(bookingId) {
      this.$emit("onViewBooking", { id: bookingId, type: "bookings" });
    },

    onAddBooking({ spotId, startDate, endDate }) {
      this.$emit("onCreateBooking", spotId, startDate, endDate);
    },

    onOutOfService({ spotId, spot, startDate, endDate }) {
      this.outOfServiceBooking = {
        ...defaultBooking,
        spot: { ...defaultBooking.spot, ...spot, id: spotId },
        start_at: startDate,
        end_at: endDate,
        status: BOOKING_STATUS_OUT_OF_SERVICE,
        organization: spot.organization,
        adults_count: 0,
        customer: null,
      };

      this.$nextTick(function () {
        this.openOutOfConfirmation = true;
      });
    },

    async putSpotOutOfService(reason = null) {
      swal.showLoading();

      try {
        const bookingData = cloneDeep({
          ...this.outOfServiceBooking,
          out_of_service_reason: reason,
          adults_count: 2,
        });
        await this.$store.dispatch("bookings/add", bookingData);

        this.openOutOfConfirmation = false;
        this.outOfServiceBooking = null;

        await this.getList();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        swal.close();
      }
    },

    async putBackBackIntoService({ bookingId, booking, startDate, endDate }) {
      try {
        const confirmation = await swal.fire({
          title: this.$t("SPOTS.BACK_INTO_SERVICE"),
          html: this.$t("SPOTS.PUT_BACK_INTO_SERVICE_THIS_SPOT", {
            start: moment(startDate).format("LL"),
            end: moment(endDate).format("LL"),
          }),
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (confirmation.isConfirmed) {
          if (moment(today).diff(endDate, "days") > 0) {
            return;
          }

          swal.showLoading();

          if (moment(today).diff(startDate, "days") <= 0) {
            await this.$store.dispatch("bookings/destroy", bookingId);
          } else {
            const bookingData = {
              id: bookingId,
              type: "bookings",
              end_at: today,
            };

            await this.$store.dispatch("bookings/update", bookingData);
          }

          await this.getList();
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        swal.close();
      }
    },
    /**
     * Détermine si une date doit être sélectionnable pour rechercher un emplacement pour reservation.
     *
     * @param {Date} date
     */
    isDisabledDate(date) {
      return new Date() >= date;
    },

    async checkInBooking(bookingId) {
      const confirmation = await swal.fire({
        title: this.$t("BOOKINGS.CHECK_IN_THIS_BOOKING"),
        type: "question",
        customClass: {
          popup: "default-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          swal.showLoading();
          await this.$store.dispatch("bookings/checkIn", bookingId);
          this.renderKey++;
          // this.closeBookingModal();
          this.$notify({
            type: "success",
            message: this.$t("BOOKINGS.BOOKING_CHECKED_IN"),
          });
          await this.getList();
        }
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkOutBooking(bookingId) {
      const confirmation = await swal.fire({
        title: this.$t("BOOKINGS.CHECK_OUT_THIS_BOOKING"),
        type: "question",
        customClass: {
          popup: "default-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          swal.showLoading();
          await this.$store.dispatch("bookings/checkOut", bookingId);
          this.renderKey++;
          // this.closeBookingModal();
          this.$notify({
            type: "success",
            message: this.$t("BOOKINGS.BOOKING_CHECKED_OUT"),
          });
          await this.getList();
        }
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async cleanSpot(spotId) {
      const confirmation = await swal.fire({
        title: this.$t("SPOTS.CLEAN_THIS_SPOT"),
        type: "question",
        customClass: {
          popup: "default-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          swal.showLoading();
          await this.$store.dispatch("spots/clean", spotId);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("SPOTS.SPOT_CLEANED"),
          });
          await this.getList();
        }
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>

<style>
.gap-16px {
  gap: 16px;
}
</style>
