var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid",class:{ loading: _vm.loading }},[(_vm.globalOrganization?.id)?_c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"map-management"},[_c('div',{staticClass:"map-management-content"},[_c('div',{ref:"mapContainer",staticClass:"image-container",class:{
            dragging: _vm.zoomOptions.isDragging,
            loading: _vm.loading,
          },on:{"mousedown":function($event){$event.preventDefault();return _vm.onMouseDownImage.apply(null, arguments)},"mousemove":function($event){$event.preventDefault();return _vm.onMouseMoveImage.apply(null, arguments)},"mouseup":function($event){$event.preventDefault();return _vm.onMouseUpImage.apply(null, arguments)},"mouseleave":function($event){$event.preventDefault();return _vm.onMouseLeaveImage.apply(null, arguments)},"wheel":function($event){$event.preventDefault();return _vm.zoom.apply(null, arguments)},"contextmenu":function($event){$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('img',{ref:"imageContainer",style:(_vm.imageStyle),attrs:{"id":"map-image","src":_vm.imageSrc}}),(_vm.showMapModal)?_c('div',{ref:"mapModalContainer",staticClass:"map-form",style:(_vm.getMapModalStyle())},[_c('div',{staticClass:"map-form-header"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("BOOKINGS.SELECTED_PERIOD"))+" ")]),(_vm.searchCriteria.dateRange.length == 2)?_c('ul',{staticClass:"dates"},[_c('li',[_vm._v(" "+_vm._s(_vm.$formatDate(_vm.searchCriteria.dateRange[0], "DD MMM YYYY"))+" ")]),_c('li',{staticClass:"sep"},[_vm._v("-")]),_c('li',[_vm._v(" "+_vm._s(_vm.$formatDate(_vm.searchCriteria.dateRange[1], "DD MMM YYYY"))+" ")])]):_vm._e(),_c('button',{staticClass:"close",on:{"click":_vm.closeMapModal}},[_c('i',{staticClass:"fal fa-times"})])]),_c('div',{staticClass:"map-form-content"},[_c('div',{staticClass:"location"},[_c('span',[_vm._v(_vm._s(_vm.$t("COMMON.SPOT")))]),_c('div',{staticClass:"location-selected"},[(_vm.spotsMap[_vm.currentSpot.spotId]?.id)?_c('div',{staticClass:"located"},[_c('div',{staticClass:"located-item"},[_c('div',{staticClass:"located-item-image"},[_c('img',{staticStyle:{"max-height":"35px","display":"inline"},attrs:{"src":_vm.spotsMap[_vm.currentSpot.spotId].category.icon
                              ? _vm.spotsMap[_vm.currentSpot.spotId].category.icon
                              : '/img/default-category-icon.svg',"alt":"icon"}})]),_c('div',{staticClass:"located-item-text"},[_c('span',[_vm._v(_vm._s(_vm.spotsMap[_vm.currentSpot.spotId].code))]),_c('span',[_vm._v(" "+_vm._s(_vm.spotsMap[_vm.currentSpot.spotId].spotType.name)+" ")])])]),_c('div',{staticClass:"located-item"},[_c('button',{staticClass:"add-booking",on:{"click":function($event){return _vm.onAddBooking(_vm.currentSpot.spotId)}}},[_vm._v(" "+_vm._s(_vm.$t("BOOKINGS.NEW_BOOKING"))+" ")])])]):_c('p',[_vm._v(_vm._s(_vm.$t("COMMON.NONE")))])])])])]):_vm._e(),_vm._l((_vm.hotspots.filter(
              (item) => item.x != 0 && item.y != 0
            )),function(hotspot,index){return _c('div',{key:index,staticClass:"hotspot",class:{},style:(_vm.getHotspotStyle(hotspot)),attrs:{"title":hotspot.code},on:{"mousedown":function($event){$event.preventDefault();return ((event) => _vm.onMouseDownHotspot(event, hotspot)).apply(null, arguments)}}},[_c('span',{staticClass:"hotspot-marker"}),_c('span',{staticClass:"hotspot-code"},[_vm._v(" "+_vm._s(hotspot.code)+" ")])])}),_c('div',{ref:"zoomControlsContainer",staticClass:"zoom-controls"},[_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.zoomIn.apply(null, arguments)}}},[_c('i',{staticClass:"far fa-plus"})]),_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.zoomOut.apply(null, arguments)}}},[_c('i',{staticClass:"far fa-minus"})])])],2),(!_vm.organizationConfig.SPOTS_MAP_IMAGE_URL)?_c('div',{staticClass:"no-image"},[_c('img',{attrs:{"src":"/img/map-placeholder.svg","alt":"map"}}),_c('p',[_vm._v(_vm._s(_vm.$t("COMMON.NO_IMAGE_ADDED")))])]):_vm._e()])])]):_c('div',{staticClass:"page-wrapper orga-select"},[_c('img',{attrs:{"src":"/img/kw-fire.svg","alt":"icon"}}),_c('p',[_vm._v(_vm._s(_vm.$t("COMMON.PLEASE_SELECT_ORGANIZATION")))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }